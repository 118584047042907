




























import { Component, Vue } from 'vue-property-decorator'

import CRUDTable from '@/views/admin/CRUD/CRUDTable.vue'

@Component({
    components: {
        CRUDTable
    }
})
export default class CreateModel extends Vue {
    selectedModel = { Name: null, Attributes: [] }

    isLoading = true

    get models() {
        return this.$store.getters['adminCrud/models']
    }

    get modelNames() {
        return this.models.map((_: { Name: any }) => _.Name)
    }

    created() {
        this.$store.dispatch("adminCrud/Init")
        .then(() => {
            this.selectedModel = this.models[0]
            
            this.isLoading = false
        })
        .catch((err) => console.error(err))
    }
}
