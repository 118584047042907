


































































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component({
    components: {
    }
})
export default class CRUDTable extends Vue {
    @Prop({ required: true })
    readonly modelName!: string

    @Prop({ required: true })
    readonly attributes!: any[]

    editedItem = this.createEmptyInstance()
    defaultItem = this.createEmptyInstance()

    isCreated = false
    loading = true
    refresh = 0

    //Errors
    hasError = false
    errorMessage = ''

    search = ''

    dialog = false
    dialogDelete = false
    editedIndex = -1
    tableOptions = {
      itemsPerPage: 5
    }

    get headers() {
        return [
          ...this.attributes
            .filter(_ => !(_.Name == "Display"))
            .map(_ => ({
                text: _.Name,
                value: (_.IsForeignKey ? "display" : "") + _.Name
              })
            ), 
          { text: 'Actions', value: 'actions', sortable: false, fixed: true }
        ]
    }

    get editProps() {
      return this.attributes.filter(_ => 
           _.Name != 'Id' && 
           _.Name != 'Display' &&
           !(_.Type == 'date' && _.Name.toLowerCase() != 'releasedat')
        )
    }
    
    get formTitle () {
        return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
    }

    get items() {
      return this.$store.getters["adminCrud/map"].get(this.modelName)
    }

    getForeignKeyList(modelName: string) {
      return this.$store.getters["adminCrud/map"]
        .get(modelName)
        .map((entity : any) => ({ value: entity, display: entity.Display}))
    }
    
    createEmptyInstance() : any {
        return {}
    }

    editItem (item: any) {
        this.editedIndex = this.items.findIndex((_: { Id: string | number }) => _.Id == item.Id)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
    }

    deleteItem (item: any) {
        this.editedIndex = this.items.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
    }

    deleteItemConfirm () {
        this.$store.dispatch("adminCrud/Delete", { Model: this.modelName, Entity: this.items[this.editedIndex]})
        .finally(this.closeDelete)
    }

    close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
        
        this.editedItem = this.createEmptyInstance()
        this.loading = false
    }

    closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
    }

    save () {
      this.loading = true

      this.attributes.forEach(({ Name, Type, IsForeignKey } : any) => {
        if (Type == 'number')
          this.editedItem[Name] = parseInt(this.editedItem[Name])
        else if (Type == 'boolean') {
          this.editedItem[Name] = this.editedItem[Name] === true
        }

        if (IsForeignKey) {
          const id = this.editedItem[Name]?.Id
          if (id != undefined) {
            this.editedItem[`${Name}Id`] = id
            delete this.editedItem[Name]
          }
        }
      })

      if (this.editedIndex == -1)
        delete this.editedItem.Id

      const mutation = { Model: this.modelName, Entity: this.editedItem }
      
      const action = (this.editedIndex > -1) ?
        this.$store.dispatch("adminCrud/Update", mutation) :
        this.$store.dispatch("adminCrud/Create", mutation)
        
      action
        .then((_ : any) => {
          if (_.err == null) {
            console.info("Request was succesful!")
          } else {
            this.errorMessage = _.err.data[0]
            this.hasError = true
          }

          this.refreshModelData();
        })
        .finally(this.close)
    }

    async refreshModelData(modelName : string = this.modelName) {
      this.loading = true

      const modelNames = [ modelName, ...this.$store.getters['adminCrud/foreignKeys'].get(modelName) ]
      const promisePool: Array<Promise<any>> = [ ]

      modelNames.forEach(async (key : string) => {
        promisePool.push(await this.$store.dispatch("adminCrud/Read", { Model: key }))
      })

      return await Promise.all(promisePool)
        .finally(() => {
          this.loading = false
        })
    }

    async fetchModelData(modelName: any) {
        return await this.$store.dispatch("adminCrud/Read", { Model: modelName });
    }

    @Watch('modelName')
    modelSwitch(newval: any) {
      this.hasError = false
      this.refreshModelData(newval)
      this.editedItem = this.createEmptyInstance();
      this.editedIndex = -1;
    }

    @Watch('dialog')
    dialogEvent(newval: any) {
        newval || this.close()
    }

    @Watch('dialogDelete')
    dialogDeleteEvent(newval: any) {
        newval || this.closeDelete()
    }

    created() {
      this.refreshModelData()
      .then(() => {
        this.isCreated = true
      })
    }
}
